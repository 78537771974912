(<template>
  <form-template :title="title"
                 :progress="progressActive">
    <div class="choose-role">
      <role-btn :params="serverBtnParams"
                class="choose-role__interpreter"
                @click.native="sendRole(serverBtnParams.type)" />
      <div class="choose-role__buyer-btns-cont">
        <role-btn :params="employeeBtnParams"
                  class="choose-role__enterprise"
                  @click.native="sendRole(employeeBtnParams.type)" />
        <role-btn :params="buyerBtnParams"
                  class="choose-role__buyer"
                  @click.native="sendRole(buyerBtnParams.type)" />
      </div>
    </div>
  </form-template>
</template>)

<script>
  import {mapGetters, mapActions} from 'vuex';
  import WizardFormTemplate from '@/components/wizard_components/WizardFormTemplate';
  import WizardChooseRoleBtn from '@/components/wizard_components/WizardChooseRoleBtn';

  export default {
    components: {
      'form-template': WizardFormTemplate,
      'role-btn': WizardChooseRoleBtn
    },
    data() { return {progressActive: false}; },
    computed: {
      ...mapGetters({
        userUid: 'UserInfoStore/userUid',
        currentRole: 'UserInfoStore/currentRole'
      }),
      title() { return this.$gettext('Choose role'); },
      serverBtnParams() {
        return {
          type: 'server', // interpreter
          title: this.$gettext('Interpreter/translator account'),
          description: this.$gettext('I\'m an interpreter and/or translator'),

          btnText: this.$gettext('Create account')
        };
      },
      buyerBtnParams() {
        return {
          type: 'buyer', // usual business
          title: this.$gettext('Individual account'),
          description: this.$gettext('I will be booking for myself'),
          btnText: this.$gettext('Create account')
        };
      },
      employeeBtnParams() {
        return {
          type: 'employee', // enterprise member
          title: this.$gettext('Organisation account'),
          description: this.$gettext('Create or join an account'),
          btnText: this.$gettext('Create account')
        };
      }
    },
    methods: {
      ...mapActions('TTWizardStore', ['setRole']),
      sendRole(role) {
        this.progressActive = true;
        this.setRole(role).then(() => {
          this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid).then(() => {
            const name = this.currentRole === 'server' ? 'ServerWizardEnterName' : 'BuyerWizardEnterName';
            this.$router.replace(this.$makeRoute({name}));
          });
        }).catch((error) => {
          if (error.data?.errors?.no_other_role?.includes('User has other role')) {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('Looks like you already have an account type. Please reload the page to continue the sign up process.')
            });
          } else {
            this.progressActive = false;
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }
        });
      }
    }
  };
</script>

<style scoped>
  .choose-role {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 120px);
    margin: 0 auto;
  }

  .choose-role__buyer-btns-cont {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    max-width: 481px;
  }

  @media (max-width: 670px) {
    .choose-role {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 30px);
    }

    .choose-role__buyer-btns-cont {
      display: block;
      width: auto;
      max-width: initial;
    }
  }
</style>
