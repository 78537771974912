(<template>
  <header class="wizard-form-header">
    <wizard-progress />
    <h1 v-if="title"
        class="wizard-form-header__title"
        v-html="title"></h1>
    <p v-if="description"
       class="wizard-form-header__description"
       v-html="description"></p>
  </header>
</template>)

<script>
  import Progress from '@/components/wizard_components/WizardProgressComponent';

  export default {
    components: {
      'wizard-progress': Progress
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style scoped>
  .wizard-form-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
  }

  .wizard-form-header__title {
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }

  .wizard-form-header__description {
    max-width: 540px;
    margin-top: 10px;
    font-size: 12px;
  }

  @media (max-width: 767px) {
    .wizard-form-header {
      margin-bottom: 20px;
    }

    .wizard-form-header__title,
    .wizard-form-header__description {
      padding: 0 10px;
    }
  }
</style>
