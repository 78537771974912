(<template>
  <div class="choose-role-btn">
    <div class="choose-role-btn__content-wrapper">
      <div :class="[`choose-role-btn__img--${params.type}`]"
           class="choose-role-btn__img"></div>
      <p
        :class="[params.type==='buyer' ? 'choose-role-btn__title__buyer': 'choose-role-btn__title']">{{ params.title }}</p>
      <p class="choose-role-btn__description">{{ params.description }}</p>
      <p class="choose-role-btn__sub__description">{{ params.subDescription }}</p>
    </div>
    <div>
      <p :class="[params.type==='buyer' ? 'choose-role-btn__title choose-role-btn__title__buyer--mobile': 'choose-role-btn__title choose-role-btn__title--mobile']">{{ params.title }}</p>
      <p class="choose-role-btn__description--mobile">{{ params.description }}</p>
      <p class="choose-role-btn__sub__description--mobile">{{ params.subDescription }}</p>
      <button class="choose-role-btn__btn">{{ params.btnText }}</button>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    }
  };
</script>

<style scoped>
.choose-role-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 230px;
  border: 1px solid #babec8;
  border-radius: 3px;
  cursor: pointer;
}

.choose-role-btn:hover {
  opacity: 0.6;
}

.choose-role-btn:active {
  box-shadow: none;
  opacity: 1;
}

.choose-role-btn__content-wrapper {
  display: block;
  flex-grow: 1;
  width: 100%;
}

.choose-role-btn__title__buyer {
  display: block;
  padding: 15px 50px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

.choose-role-btn__img {
  display: block;
  width: 100%;
  height: 100px;
  background-color: #cce6e7;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}

.choose-role-btn__img--server {
  background-image: url(~@assets/imgs/wizard_icons/interpreter-illustration.svg);
  background-size: 340px auto;
}

.choose-role-btn__img--buyer {
  background-color: #e9ddcc;
  background-image: url(~@assets/imgs/wizard_icons/bussiness-illustration.svg);
  background-size: 340px auto;
}

.choose-role-btn__img--employee {
  background-color: #bfbece;
  background-image: url(~@assets/imgs/wizard_icons/enterprise-illustration.svg);
  background-size: 340px auto;
}

.choose-role-btn__title {
  display: block;
  padding: 15px 35px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

.choose-role-btn__description {
  display: block;
  padding: 15px 0 2px 11px;
  font-weight: 400;
  font-size: 14px;
  font-family: Noto Sans, serif;
  text-align: center;
}

.choose-role-btn__sub__description {
  display: block;
  margin-bottom: 20px;
  color: #666;
  font-weight: 400;
  font-size: 12px;
  font-family: Noto Sans, serif;
  text-align: center;
}

.choose-role-btn__btn {
  display: block;
  width: calc(100% - 15px);
  margin: 0 auto;
  margin-bottom: 25px;
  padding: 10px;
  border: 1px solid #ff5b24;
  border: 1px solid var(--color-secondary);
  border-radius: 3px;
  color: #ff5b24;
  color: var(--color-secondary);
  font-size: 16px;
  text-align: center;
}

.choose-role-btn__btn:focus {
  box-shadow: inset 0 0 0 2px #3af;
}

.choose-role-btn__title--mobile {
  display: none;
}

.choose-role-btn__title__buyer--mobile {
  display: none;
}

.choose-role-btn__description--mobile {
  display: none;
}

.choose-role-btn__sub__description--mobile {
  display: none;
}

@media (max-width: 670px) {
  .choose-role-btn {
    flex-direction: row;
    width: 100%;
    max-width: 310px;
    margin-bottom: 20px;
  }

  .choose-role-btn__description {
    display: none;
  }

  .choose-role-btn__sub__description {
    display: none;
  }

  .choose-role-btn__content-wrapper {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    width: 125px;
  }

  .choose-role-btn__title {
    display: none;
  }

  .choose-role-btn__title__buyer {
    display: none;
  }

  .choose-role-btn__title--mobile {
    display: block;
    padding: 10px 4px;
  }

  .choose-role-btn__title__buyer--mobile {
    display: block;
    padding: 10px 4px;
  }

  .choose-role-btn__description--mobile {
    display: block;
    padding: 10px 4px;
  }

  .choose-role-btn__sub__description--mobile {
    display: block;
    padding: 10px 4px;
  }

  .choose-role-btn__img {
    height: initial;
  }

  .choose-role-btn__img--server {
    background-image: url(~@assets/imgs/wizard_icons/interpreter-illustration.svg);
    background-size: 360px auto;
  }

  .choose-role-btn__img--buyer {
    background-color: #e9ddcc;
    background-image: url(~@assets/imgs/wizard_icons/bussiness-illustration.svg);
    background-size: 360px auto;
  }

  .choose-role-btn__img--employee {
    background-color: #bfbece;
    background-image: url(~@assets/imgs/wizard_icons/enterprise-illustration.svg);
    background-size: 360px auto;
  }
}
</style>
