(<template>
  <div :class="{'wizard-progress--hidden': hideProgress}"
       class="wizard-progress">
    <router-link v-if="prevStep"
                 :to="{name: prevStep}"
                 class="wizard-progress__back-link">
      <span>{{ $gettext('Back') }}</span>
    </router-link>
    <div class="wizard-progress__steps-cont">
      <span v-for="(step, index) in stepsForRender"
            :key="index"
            :class="{'wizard-progress__step--active': step == routeName,
                     'wizard-progress__step--past': isPastStep(index)}"
            class="wizard-progress__step"></span>
    </div>
  </div>
</template>)

<script>
  import {mapState, mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', [
        'currentRole',
        'isEmailVerified'
      ]),
      ...mapState('UserInfoStore', {
        forEnterprise: ({additionalUserInfo}) => additionalUserInfo.forEnterprise
      }),
      hideProgress() { return this.$route.name == 'WizardChooseRole' || this.$route.name == 'BuyerWizardVerifyEmail'; },
      routeName() { return this.$route.name; },
      interpreterSteps() {
        return [
          'ServerWizardEnterName',
          'ServerWizardEnterPhone',
          'ServerWizardEnterAddress',
          'ServerWizardAddGender'
        ];
      },
      buyerSteps() {
        const stepsList = [
          'BuyerWizardEnterName',
          'BuyerWizardEnterPhone'
        ];
        // emailVerified = true --> for old users. Doesn't need enterprise step
        if (!this.isEmailVerified && this.forEnterprise) {
          stepsList.push('BuyerRegisterEnterprise');
          stepsList.push('BuyerEnterprisePayment');
        }
        return stepsList;
      },
      stepsForRender() {
        switch (this.currentRole) {
          case 'server':
            return this.interpreterSteps;
          case 'buyer':
          case 'employee':
            return this.buyerSteps;
        }
      },
      prevStep() {
        if (this.stepsForRender) {
          for (let i = 0, lim = this.stepsForRender.length; i < lim; i += 1) {
            if (this.stepsForRender[i] == this.routeName && i > 0) return this.stepsForRender[i - 1];
          }
        }
      }
    },
    methods: {
      isPastStep(index) {
        for (let i = 0, lim = this.stepsForRender.length; i < lim; i += 1) {
          if (this.stepsForRender[i] == this.routeName && index < i) return true;
        }
      }
    }
  };
</script>

<style scoped>
  .wizard-progress {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 60px;
  }

  .wizard-progress--hidden {
    visibility: hidden;
  }

  .wizard-progress__back-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 35px;
    font-size: 12px;
    text-transform: uppercase;
  }

  .wizard-progress__back-link::before,
  .wizard-progress__back-link::after {
    content: '';
    position: absolute;
    left: 18px;
    display: block;
    width: 17px;
    height: 2px;
    background-color: #000;
  }

  .wizard-progress__back-link::before {
    top: 40%;
    transform: rotate(-45deg);
  }

  .wizard-progress__back-link::after {
    top: 58%;
    transform: rotate(45deg);
  }

  .wizard-progress__steps-cont {
    display: flex;
    width: auto;
  }

  .wizard-progress__step {
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid #a7abbd;
    border-radius: 50%;
  }

  .wizard-progress__step:not(:last-child) {
    margin-right: 15px;
  }

  .wizard-progress__step--active {
    background-color: #d3d5de;
  }

  .wizard-progress__step--past {
    border: 1px solid #ff5b24;
    border: 1px solid var(--color-secondary);
    background-color: #ff5b24;
    background-color: var(--color-secondary);
  }
</style>
