(<template>
  <choose-role />
</template>)

<script>
  import WizardChooseRole from '@/components/wizard_components/WizardChooseRole';

  export default {
    components: {
      'choose-role': WizardChooseRole
    }
  };
</script>
