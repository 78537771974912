(<template>
  <div :class="{'in-progress': progress}"
       class="form-template is-progress-bar">
    <form-header :title="title"
                 :description="description" />
    <div class="form-template__content-wrapper">
      <div class="form-template__content-cont">
        <slot></slot>
      </div>
    </div>
  </div>
</template>)

<script>
  import FormHeader from '@/components/wizard_components/WizardFormHeader';

  export default {
    components: {
      'form-header': FormHeader
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      progress: Boolean
    }
  };
</script>

<style scoped>
  .form-template {
    display: block;
    width: 100%;
  }

  .form-template__content-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 80px;
  }

  .form-template__content-cont {
    display: inline-block;
    width: 100%;
  }
</style>
